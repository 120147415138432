import React from "react";
import Image from "gatsby-image";
import { graphql } from "gatsby";
import styled from "styled-components";
import Layout from "../components/layout";
import ArticlePreview from "../components/ArticlePreview";
import slugify from "slugify";

export const query = graphql`
  query querySingleArticle($id: String!) {
    allDatoCmsArticle {
      nodes {
        title
        date
        articleContent {
          ... on DatoCmsHeading {
            id
            headingContent
          }
        }
      }
    }

    datoCmsArticle(id: { eq: $id }) {
      title
      featuredImage {
        fixed(width: 1600) {
          ...GatsbyDatoCmsFixed_tracedSVG
        }
      }
      author
      date
      articleContent {
        ... on DatoCmsHeading {
          id
          headingContent
        }
        ... on DatoCmsParagraph {
          id
          paragraphContent
        }
        ... on DatoCmsArticleImage {
          id
          imageData {
            fixed(width: 900) {
              ...GatsbyDatoCmsFixed_tracedSVG
            }
          }
        }
      }
    }
  }
`;

// const colorNumber = {
//   color: Object.keys(query),
// };
// background-color: rgb(100, 100, ${(props) => colorNumber.color});

const ArticlesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
  width: 400px;
  position: relative;
  top: calc(70vh + 20px);
  h3 {
    font-size: 22px;
    text-align: center;
  }
  div {
    position: relative;
    border-radius: 50px 0;
    padding: 30px;
    @media (max-width: 1299px) {
      padding: 10px;
    }
    p {
      font-size: 12px;
      color: gray;
    }
    p ~ p {
      font-size: inherit;
      color: inherit;
    }
    span {
      text-align: center;
      display: inherit;
      margin: 15px auto;
      color: #d71920;
      font-weight: bold;
    }
  }
  @media (max-width: 1299px) {
    width: 300px;
  }
  @media (max-width: 1023px) {
    display: none !important;
  }
`;

const ArticleHeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: -20px;
  left: 0px;
  width: 100vw;
  padding: 20px;
  height: 45vh;
  min-height: 333px;
  background-color: black;
  p {
    background-color: white;
    padding: 2px 15px;
    margin-bottom: 0;
  }
  h1 {
    color: white;
    font-weight: bold;
    margin: 8px auto;
    @media (max-width: 479px) {
      font-size: 26px;
      text-align: center;
    }
  }
  h4 {
    color: #d71920;
  }

  .gatsby-image-wrapper {
    position: absolute !important;
    overflow: hidden !important;
    display: inline-block !important;
    width: 74vw !important;
    max-width: 1600px;
    // height: 500px !important;
    max-height: 50vh;
    min-height: 333px;
    bottom: -230px;
    img {
      border-radius: 140px 0;
    }
    @media (max-width: 1349px) {
      width: 90vw !important;
    }
    @media (max-width: 767px) and (min-height: 600px) {
      bottom: -320px;
    }
    @media (max-width: 767px) and (orientation: landscape) and (min-height: 600px) {
      bottom: -320px;
      min-height: auto;
    }
    // @media (max-width: 540px) and (min-height: 700px) {
    //   bottom: -70%;
    // }
    @media (min-height: 660px) and (max-width: 479px) {
      position: absolute !important;
      bottom: -320px;
    }
    @media (max-width: 479px) {
      position: absolute !important;
      bottom: -250px;
    }
  }
`;

const ArticleContent = styled.div`
  // background-color: rgba(0, 0, 0, 0.85);
  color: #2d2d2d;
  // position: relative;
  top: 100px;
  margin-bottom: 100px;
  padding: 20px 25px;
  @media (min-width: 1024px) {
    // padding: 60px 80px;
  }
  @media (max-width: 767px) {
    padding: 0;
  }
  .txtWrapper {
    // height: 100vh;
    position: relative;
    top: 70vh;
    max-width: 1380px;
    max-width: max-width: 1920px;
    margin-left: auto;
    padding-bottom: 100px;
    @media (max-height: 979px) and (orientation: portrait)  {
      top: 800px;
    }
    @media  (max-height: 920px) {
      top: 640px;
    }
    @media (max-height: 920px) and (orientation: portrait)  {
       {
          top: 740px;
        }
    }
    
    @media  (max-height: 800px) and (orientation: portrait){
      top: 700px;
    }
 
    @media  (max-height: 700px) and (orientation: portrait) {
      top: 640px;
    }

    @media  (max-height: 599px) {
      top: 640px;
    }
  
    @media(max-height: 379px) {
        top: 550px;
        }
    .gatsby-image-wrapper {
      height: 500px !important;
      width: 100% !important;
      margin: 25px 0;
      @media(min-width: 1600px) {
        height: 600px !important;
      }
      @media(min-width: 1921px) {
        height: 700px !important;
      }
    
    }
    
    p {
      margin: 25px auto;
    }
   
    @media(max-width: 479px) {
      h2 {
        text-align: center
      }
    }

  }
`;

const PostContainer = styled.div`
  position: relative;
  z-index: 100;
  display: grid;
  grid-template-columns: 3fr 1fr;
  grid-template-rows:
    minmax(100px, max-content)
    repeat(auto-fill, 200px) 50%;
  grid-area: last-line;
  min-height: 140vh;
  gap: 20px;
  width: 100vw;
  margin: 0 auto;
  background-color: rgb(243, 240, 240);
  padding: 0 10vw;
  @media (min-width: 1024px) and (orientation: landscape) {
    min-height: 140vh;
  }
  @media (max-width: 1349px) {
    padding: 0 5vw;
  }
  @media (min-width: 1200px) {
    margin-bottom: -450px;
  }
  @media (max-width: 1299px) {
    // grid-template-rows:
    //   minmax(100px, max-content)
    //   repeat(auto-fill, 200px) 40%;
    gap: 0;
  }
  @media (max-width: 1023px) {
    grid-template-columns: 1fr;
  }
`;

const PostLayout = ({ data }) => {
  const {
    allDatoCmsArticle: { nodes },
  } = data;
  // Sort articles by date in descending order
  const sortedArticles = nodes.sort(
    (a, b) => new Date(b.date) - new Date(a.date)
  );
  return (
    <Layout>
      <PostContainer>
        <ArticleContent className="articleContent">
          <ArticleHeaderWrapper>
            <p>{data.datoCmsArticle.date}</p>
            <h1>{data.datoCmsArticle.title}</h1>
            <h4>{data.datoCmsArticle.author}</h4>

            <Image fixed={data.datoCmsArticle.featuredImage.fixed} />
          </ArticleHeaderWrapper>
          <div className="txtWrapper">
            {data.datoCmsArticle.articleContent.map((item) => {
              const itemKey = Object.keys(item)[1];
              switch (itemKey) {
                case "paragraphContent":
                  return <p key={item.id}>{item[itemKey]}</p>;
                case "headingContent":
                  return <h2 key={item.id}>{item[itemKey]}</h2>;
                case "imageData":
                  return <Image key={item.id} fixed={item[itemKey].fixed} />;
                default:
                  return null;
              }
            })}
          </div>
        </ArticleContent>
        <ArticlesWrapper className="news-container-wrapper-elements">
          <h3>Ostatnie wpisy</h3>
          {sortedArticles
            .map(({ title, featuredImage, date, articleContent }) => (
              <ArticlePreview
                key={title}
                title={title}
                date={date}
                articleContent={[
                  articleContent.map((el) => {
                    return el.headingContent;
                  }),
                ]}
                slug={slugify(title, { lower: true })}
              />
            ))
            .splice(0, 3)}
        </ArticlesWrapper>
      </PostContainer>
    </Layout>
  );
};

export default PostLayout;
